import { useTitle } from "../util/useTitle";
import { useFooter } from "../util/useFooter";

export default function NomaiWriting() {
  useTitle("Nomai Writing");
  useFooter({link: "/NomaiWriting", name: "Nomai Writing"});

  return (
  <div>
    <img alt="Nomai text generated by the program" src="assets/images/nomaiText.png" width="400" height="200" />
    <p>This is a word processor that works with a 2D language. The script is based off a videogame called Outer Wilds. 
      In Outer Wilds, there is a species of aliens called Nomai. While we see and can translate their writing, it is actually 
      gibberish. I took the visuals from the game and turned it into a script that you can write English in. Then I took 
      that script and made a program that can generate the script.</p>
  </div>);
  };