import '../css/aboutMe.css';
import { useTitle } from "../util/useTitle";

export default function About() {
  useTitle("About");

  return (
    <div className='about'>
      <div className="contactInfo">
        <img alt="Melissa smiling" src="assets/images/melissaPortrait.jpg" />
        <h2>Contact Information</h2>
        <ul>
            <li>Email: mscron7@gmail.com</li>
            <li>GitHub: <a href="https://github.com/IgpayAtenlay">github.com/IgpayAtenlay</a></li>
            <li>LinkedIn: <a href="https://linkedin.com/in/melissa-cron/">linkedin.com/in/melissa-cron/</a></li>
        </ul>
      </div>

      <div>
        <p>Going into college, I wanted to challenge myself. Many people told me I would excel at STEM. At the time, I thought it was too easy. 
          So I decided to do something that challenged myself and I was passionate about. I did my degree in Music Education.</p>
        <p>To my surprise, the career I chose to be challenging was challenging. By the end of my first year teaching I realized I didn’t enjoy 
          the constant stress and went back to the drawing table. This time looking for something that is equally interesting, but in an area 
          where I excel.</p>
        <p>As a stop-gap I decided to take up tutoring. I am currently tutoring STEM subjects: chemistry, math, and SATs. While I love my current 
          job, I want to find something full time. Something I could see myself doing long term.</p>
        <p>Enter programming. I had spent my whole life learning the logic behind programming, but I had never taken that next step and learned 
          a real language. I spent this past year learning every language I could get my hands on. Java, HTML, CSS, JavaScript, and more. It 
          all came so easy to me. Sure, I had moments where I would pace in a circle and had to explain my problem to a rubber duck. But each 
          problem I ran into I quickly found a solution.</p>
        <p>So why choose me?</p>
        <p>I am a quick learner. A blind person once approached me to help her with orientation and mobility training. I had never even heard of 
          that before. So I spent a week learning about it and three months teaching it to her. In the end, she was able to pass her orientation 
          and mobility test and qualify for a service dog.</p>
        <p>Teamwork is important to me. Everyone has flaws. A good team is one where I am able to help others when they struggle and others are 
          able to help me when I struggle. Asking for help is an underrated skill. Being able to give help is the best feeling in the world.</p>
      </div>
    </div>
  );
};